<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import moment from 'moment'
export default {
  name: 'physicalCouponDetail',
  data() {
    return {
      ...api.command.getState(),
      storeData: [],
      attractionsData: [],
      hotelData: [],
      detail: {
        receiveNum: null,
        expireType: '1',
        effectiveDays: null,
        giveNum: null,
      },
      id:this.$route.query.id
    }
  },
  watch: {
    'detail.expireType'(a, b) {
      if (a == 2) {
        this.detail.useEndTime = null
        this.detail.useStartTime = null
      }
    },
  },
  mounted() {
    const { id } = this.$route.query
    this.storeSearch()
    this.attractionsSearch()
    this.hotelSearch()
    id &&
      api.command.getDetail
        .call(this, {
          url: '/duiwuCoupon/detail?id=' + id,
        })
        .then((result) => {
          const relationList = result.relationList
          this.detail.relationList = relationList.filter((d) => d.relationType == 0).map((e) => e.shopId)
          this.detail.attractions = relationList.filter((d) => d.relationType == 1).map((e) => e.shopId)
          this.detail.hotels = relationList.filter((d) => d.relationType == 2).map((e) => e.shopId)
          this.$forceUpdate()
        })
  },
  methods: {
    async storeSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmMainBody/list?mainBodyName=${value}`,
      })
      this.storeData = searchResult.map((el) => {
        return {
          name: el.mainBodyName,
          value: el.id,
          relationType: 0,
        }
      })
    },
    async attractionsSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/farmTicketScenic/list?productName=${value}`,
      })
      this.attractionsData = searchResult.map((el) => {
        return {
          name: el.name,
          value: el.id,
          relationType: 1,
        }
      })
    },
    async hotelSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/hotel/list?name=${value}`,
      })
      this.hotelData = searchResult.map((el) => {
        return {
          name: el.productName,
          value: el.id,
          relationType: 2,
        }
      })
    },
    hanldeIssueType() {
      return [
        {
          name: '发放时间',
          type: 'row',
          cols: 8,
          gutter: 10,
          display: this.detail.giveType == '0',
          rules: [
            {
              required: true,
            },
          ],
          children: [
            {
              label: '发放开始日期',
              type: 'datePicker',
              cols: 12,
              key: 'giveStartTime',
              props: {
                mode: 'date',
                format: 'YYYY-MM-DD',
                valueFormat: 'YYYY-MM-DD HH:mm:ss',
                placeholder: '请选择发放开始日期',
              },
              rules: [
                {
                  required: true,
                  type: 'date',
                },
              ],
            },
            {
              label: '发放开始时间',
              type: 'timePicker',
              cols: 12,
              key: 'giveStartTime',
              props: {
                format: 'HH:mm',
                allowClear: false,
                valueFormat: 'YYYY-MM-DD HH:mm:ss',
                placeholder: '请选择发放开始时间',
              },
              rules: [
                {
                  required: true,
                  type: 'date',
                },
              ],
            },
          ],
        },
        {
          name: '发放结束',
          type: 'row',
          cols: 8,
          gutter: 10,
          display: this.detail.giveType == '0',
          rules: [
            {
              required: true,
            },
          ],
          children: [
            {
              label: '发放结束日期',
              type: 'datePicker',
              cols: 12,
              key: 'giveEndTime',
              props: {
                mode: 'date',
                format: 'YYYY-MM-DD',
                valueFormat: 'YYYY-MM-DD HH:mm:ss',
                placeholder: '请选择发放结束日期',
              },
              rules: [
                {
                  required: true,
                  type: 'date',
                },
              ],
            },
            {
              label: '发放结束时间',
              type: 'timePicker',
              cols: 12,
              key: 'giveEndTime',
              props: {
                format: 'HH:mm',
                allowClear: false,
                valueFormat: 'YYYY-MM-DD HH:mm:ss',
                placeholder: '请选择发放结束时间',
              },
              rules: [
                {
                  required: true,
                  type: 'date',
                },
              ],
            },
          ],
        },
        {
          display: true,
          name: '券发放量',
          type: 'inputNumber',
          cols: 12,
          key: 'giveNum',
          rules: [
            {
              required: true,
              type: 'number',
            },
          ],
          props: {
            min: 0,
          },
        },
        {
          display: this.detail.giveType == '0',
          name: '发放客户类型',
          type: 'radioButton',
          key: 'userType',
          cols: 4,
          defaultValue: '0',
          typeData: [
            {
              name: '所有用户',
              value: '0',
            },
            {
              name: '指定用户',
              value: '1',
            },
          ],
          rules: [
            {
              required: true,
              type: 'string',
              message: '请选择发放客户类型',
            },
          ],
        },
        {
          display: this.detail.userType == '1',
          name: '金额范围',
          type: 'row',
          cols: 12,
          gutter: 15,
          children: [
            {
              label: '最低购买金额',
              type: 'input',
              cols: 12,
              key: 'minBuyMoney',
            },
            {
              label: '最高购买金额',
              type: 'input',
              cols: 12,
              key: 'maxBuyMoney',
            },
          ],
        },
        {
          display: this.detail.userType == '1',
          name: '购买次数范围',
          type: 'row',
          cols: 12,
          gutter: 15,
          children: [
            {
              label: '最低购买次数',
              type: 'input',
              cols: 12,
              key: 'minBuyNum',
            },
            {
              label: '最高购买次数',
              type: 'input',
              cols: 12,
              key: 'maxBuyNum',
            },
          ],
        },
      ].filter((e) => e.display)
    },

    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            display: true,
            name: '商品名称',
            type: 'input',
            cols: 12,
            key: 'name',
            props: {
              maxLength: 12,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '类型',
            type: 'row',
            cols: 12,
            rules: [
              {
                required: true,
              },
            ],
            children: [
              {
                type: 'radioButton',
                key: 'duiwuType',
                cols: 24,
                defaultValue: '0',
                disabled:this.id,
                typeData: [
                  {
                    name: '实物券',
                    value: '0',
                  },
                  {
                    name: '实物卡',
                    value: '1',
                  },
                ],
              },
            ],
          },
          {
            display: this.detail.duiwuType == 0,
            name: '每人领取限额',
            type: 'inputNumber',
            cols: 12,
            key: 'receiveNum',
            rules: [
              {
                required: true,
                type: 'number',
              },
            ],
          },
          {
            display: true,
            name: '有效期',
            type: 'select',
            cols: 4,
            key: 'expireType',
            typeData: [
              {
                name: '固定时间',
                value: '1',
              },
              {
                name: '领取后有效时间',
                value: '2',
              },
            ],
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          ...this.isTime(),
        ].filter((d) => d.display),
      }
    },
    isTime() {
      return [
        {
          display: this.detail.expireType == 1,
          name: '开始结束时间',
          type: 'rangePicker',
          cols: 8,
          keys: ['useStartTime', 'useEndTime'],
          rules: [
            {
              required: true,
              message: '请选择开始结束时间',
            },
          ],
        },
        {
          display: this.detail.expireType == 2,
          name: '天数',
          type: 'inputNumber',
          cols: 8,
          key: 'effectiveDays',
          rules: [
            {
              required: true,
              type: 'number',
            },
          ],
        },
      ].filter((e) => e.display)
    },
    getForm2() {
      return {
        title: '使用对象',
        type: 'cardForm',
        gutter: 10,
        data: [
          {
            display: true,
            name: '使用方式(邮寄到家：用户要填写邮寄地址   二维码核销：关联具体核销商户)',
            type: 'row',
            cols: 24,
            children: [
              {
                type: 'radioButton',
                key: 'useType',
                cols: 24,
                defaultValue: '0',
                typeData: [
                  {
                    name: '二维码核销',
                    value: '0',
                  },
                  {
                    name: '邮寄到家',
                    value: '1',
                  },
                ],
              },
            ],
          },
          {
            display: this.detail.useType == '0',
            name: '关联店铺（可关联多个商家）',
            type: 'dropDownMultiInput',
            cols: 24,
            key: 'shopName3',
            onInputSearch: this.storeSearch,
            typeData: this.storeData,
            valueKey: 'relationList',
            rules: [
              {
                required: false,
              },
            ],
          },
          {
            display: this.detail.useType == '0',
            name: '关联景点（可关联多个商家）',
            type: 'dropDownMultiInput',
            cols: 24,
            key: 'shopName1',
            onInputSearch: this.attractionsSearch,
            typeData: this.attractionsData,
            valueKey: 'attractions',
            rules: [
              {
                required: false,
              },
            ],
          },
          {
            display: this.detail.useType == '0',
            name: '关联酒店（可关联多个商家）',
            type: 'dropDownMultiInput',
            cols: 24,
            key: 'shopName2',
            onInputSearch: this.hotelSearch,
            typeData: this.hotelData,
            valueKey: 'hotels',
            rules: [
              {
                required: false,
              },
            ],
          },
        ].filter((e) => e.display),
      }
    },
    getForm3() {
      return {
        title: '发放设置',
        type: 'cardForm',
        data: [
          {
            name: '发放方式',
            type: 'select',
            cols: 8,
            key: 'giveType',
            typeData: [
              {
                name: '只限二维码领取',
                value: '0',
              },
              {
                name: '营销活动',
                value: '1',
              },
              {
                name: '数字兑换码',
                value: '2',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
          ...this.hanldeIssueType(),
        ],
      }
    },
    getForm4() {
      return {
        type: 'cardForm',
        title: '商品照片（尺寸750*420PX）限制1张',
        data: [
          {
            type: 'upload',
            maxLength: 1,
            cols: 24,
            key: 'img',
            rules: [
              {
                required: true,
                message: '请上传图片',
                type: 'string',
              },
            ],
          },
        ],
      }
    },

    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: (formData) => {
            const data = { ...formData }
            if (data.expireType == 1) {
              if (data.useEndTime && data.useStartTime) {
                data.useEndTime = `${moment(data.useEndTime, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss')}`
                data.useStartTime = `${moment(data.useStartTime).format('YYYY-MM-DD HH:mm:ss')}`
              } else {
                this.$message.warning('请选择开始结束时间！')
                return
              }
            }

            let relationList = null
            if (data.useType == 0) {
              const data0 = data.relationList ? data.relationList.map((e) => {
                return {
                  shopId: e,
                  relationType: 0,
                }
              }) : []
              const data1 = data.attractions
                ? data.attractions.map((e) => {
                    return {
                      shopId: e,
                      relationType: 1,
                    }
                  })
                : []
              const data2 = data.hotels
                ? data.hotels.map((e) => {
                    return {
                      shopId: e,
                      relationType: 2,
                    }
                  })
                : []
              relationList = [...data0, ...data1, ...data2]
            }

            api.command[data.id ? 'edit' : 'create']
              .call(this, {
                url: '/duiwuCoupon',
                params: {
                  ...data,
                  relationList,
                },
                isPost: false,
              })
              .then((result) => {
                this.$router.push('/marketingCenter/physicalCoupon')
              })
          },
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.push('/marketingCenter/physicalCoupon'),
        },
      ]
      const right = [
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: `/duiwuCoupon?id=${this.detail.id}`,
              isPost: false,
            })
            setTimeout(() => {
              this.$router.push('/marketingCenter/physicalCoupon')
            }, 500)
          },
        },
      ]
      return {
        left,
        right: this.detail.id && right,
      }
    },
  },
  render() {
    return (
      <DetailFormGroup
        form={this.detail}
        foot={this.getFoot()}
        data={[this.getForm1(), this.getForm2(), this.getForm4(), this.getForm3()]}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
